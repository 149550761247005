import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageWrapper from "../components/PageWrapper"
// import Seo from "../components/Seo"
import Seo2 from "../components/Seo2"
// import Container from "../components/Container"
// import { PageTitle } from "../components/PageTitles"
// import Button from "../components/Button"
// import ContactForm from "../components/ContactForm"


const InfoPage = () => {
    const data = useStaticQuery(graphql`
    query AboutPageQuery {
        wpSnippet(id: {eq: "cG9zdDoxMTQ4"}) {
          title
          content
        }
      }
  `)

  return (
    <Layout>
      {/* <Seo title="About" description={`About Lighter Than Air and Lighter Than Air Records, an artist-oriented management company and record label based in North America and beyond.`} /> */}
        <PageWrapper>

          <div className={`flex flex-col md:space-y-0 sm:space-y-14 space-y-10 pb-10 md:py-20 lg:py-10 lg:pb-14`}>
            <h1 className={`hidden`}>About Lighter Than Air</h1>
            <div className={`md:grid md:grid-cols-2 md:gap-14 lg:gap-24 xl:gap-56 2xl:gap-80 leading-[160%] xl:text-xl tracking-wide md:pb-10 md:space-y-0 space-y-7`} dangerouslySetInnerHTML={{__html: data.wpSnippet.content}} />
            <div className={`md:pl-7 pl-5 border-l-[3px] border-primary-default font-mono text-sm`}>
              <address className={`not-italic uppercase`}>
                <span className={`block`}>304b-6560 Av. de l’Esplanade</span>
                <span className={`block`}>Montréal, Québec, Canada</span>
                <span className={`block`}>H2V 4L5</span>
              </address>
              <a style={{textDecorationThickness: '1.5px'}} className={`inline-block underline lowercase`} href={`mailto:info@lighterair.com`} target={`blank`} rel={`noopener noreferrer`} title={`Email Us`}>info@lighterair.com</a>
              </div>
          </div>

          {/* <div className={`md:grid md:grid-cols-5 lg:grid-cols-2 md:gap-20 lg:gap-28 xl:gap-36 flex flex-col md:space-y-0 sm:space-y-14 space-y-10`}>
            <div className={`lg:col-span-1 md:col-span-2`}>
              <PageTitle className={`md:hidden block`}>About <br/>Lighter <br/>Than Air</PageTitle>
              <div className={`space-y-5 lg:space-y-7 xl:space-y-10`}>
              <div className={`leading-[160%] md:max-w-[90%] lg:max-w-[80%] xl:max-w-[75%] xl:text-xl tracking-wide xl:pt-2`} dangerouslySetInnerHTML={{__html: data.wpSnippet.content}} />
              <div className={`md:pl-7 pl-5 border-l-[3px] border-primary-default font-mono text-sm`}>
              <address className={`not-italic uppercase`}>
                <span className={`block`}>304b-6560 Av. de l’Esplanade</span>
                <span className={`block`}>Montréal, Québec, Canada</span>
                <span className={`block`}>H2V 4L5</span>
              </address>
              <a style={{textDecorationThickness: '1.5px'}} className={`inline-block underline lowercase`} href={`mailto:info@lighterair.com`} target={`blank`} rel={`noopener noreferrer`} title={`Email Us`}>info@lighterair.com</a>
              </div>
              </div>
            </div>
            <div className={`lg:col-span-1 md:col-span-3`}>
            <ContactForm />
            </div>

          </div> */}
        </PageWrapper>
    </Layout>
  )
}

export const Head = () => <Seo2 title="Info" description={`About Lighter Than Air and Lighter Than Air Records, an artist-oriented management company and record label based in North America and beyond.`} />

export default InfoPage
